<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99735 1.62395C7.5399 1.0814 8.25336 0.749043 9.01118 0.679299V0.665527H9.31118C10.179 0.665527 11.0113 1.01028 11.625 1.62395C12.2387 2.23761 12.5834 3.06992 12.5834 3.93778V11.0712C12.5834 11.657 12.468 12.2371 12.2439 12.7784C12.0197 13.3196 11.6911 13.8114 11.2768 14.2257C10.8625 14.6399 10.3707 14.9686 9.82949 15.1927C9.28824 15.4169 8.70813 15.5323 8.12228 15.5323C7.53644 15.5323 6.95633 15.4169 6.41508 15.1927C5.87382 14.9686 5.38203 14.6399 4.96777 14.2257C4.55352 13.8114 4.22491 13.3196 4.00072 12.7784C3.77652 12.2371 3.66113 11.657 3.66113 11.0712V6.91003C3.66113 6.41604 4.06159 6.01558 4.55558 6.01558C5.04957 6.01558 5.45003 6.41604 5.45003 6.91003V11.0712C5.45003 11.7799 5.73157 12.4596 6.23272 12.9607C6.73386 13.4619 7.41356 13.7434 8.12228 13.7434C8.83101 13.7434 9.51071 13.4619 10.0119 12.9607C10.513 12.4596 10.7945 11.7799 10.7945 11.0712V3.93778C10.7945 3.74298 10.7562 3.55009 10.6816 3.37012C10.6071 3.19016 10.4978 3.02663 10.3601 2.88889C10.2223 2.75115 10.0588 2.64189 9.87884 2.56734C9.69887 2.4928 9.50598 2.45443 9.31118 2.45443C9.11639 2.45443 8.9235 2.4928 8.74353 2.56734C8.56356 2.64189 8.40004 2.75115 8.2623 2.88889C8.12456 3.02663 8.01529 3.19016 7.94075 3.37012C7.8662 3.55009 7.82783 3.74298 7.82783 3.93778V11.0712C7.82783 11.1493 7.85886 11.2242 7.91408 11.2794C7.9693 11.3346 8.04419 11.3656 8.12228 11.3656C8.20038 11.3656 8.27527 11.3346 8.33049 11.2794C8.38571 11.2242 8.41673 11.1493 8.41673 11.0712V5.12668C8.41673 4.63269 8.81719 4.23223 9.31118 4.23223C9.80518 4.23223 10.2056 4.63269 10.2056 5.12668V11.0712C10.2056 11.6237 9.98614 12.1536 9.59544 12.5443C9.20473 12.935 8.67482 13.1545 8.12228 13.1545C7.56975 13.1545 7.03984 12.935 6.64913 12.5443C6.25843 12.1536 6.03893 11.6237 6.03893 11.0712V3.93778C6.03893 3.06992 6.38369 2.23761 6.99735 1.62395Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPaperclip'
}
</script>
